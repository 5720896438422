import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Common} from "../common/common";
declare var $ ;
@Component({
  selector: 'app-partnerdata',
  templateUrl: './partnerdata.component.html',
  styleUrls: ['./partnerdata.component.css']
})
export class PartnerdataComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http :HttpClient,private router:Router) { }
  p:number=1;
  items:number=10;
  totalproduct:any=[];
  partners:any=[];
  itemsvalue:number=10;
  PartnerDataId:any;
  selectValue:number;
  status:any;
  itemvalue(){
    this.items=this.itemsvalue;
  }
  partnersData:any=[];

  getDataId(id)
  {
    this.PartnerDataId=id;
    this.partnersData.map((item) => {
      if(item.id == id){
        if (item.active == 1)
        {
          this.selectValue = 1
        }
        else {
          this.selectValue=0
        }
      }
    })
  }
  ChangeStatus(id)
  {
    const DataId=id;
    let status =this.status;
    if (status !=null)
    {
      let StatusData=
        {
          id:DataId,
          status:status
        }
      this.http.post(this.configuration.url + '/changepartnerdatastatus',StatusData).subscribe((data)=>{
        this.selectValue = null
        this.status=null
        $('.dialog').modal('hide')
        this.getPartnersData();
      });
    }
  }
  viewPartner(id)
  {
    const encryptedId=Common.encryptId(id);
    this.router.navigate(['/partnerview',encryptedId]);
  }
  getPartnersData(){
    this.http.get(this.configuration.url + '/getPartnerData' ).subscribe((data:any)=>{
      this.partnersData=data;
      this.totalproduct=data.length
    })
  }
  deleteData(id:any)
  {
    if (confirm("Are you sure you want to delete this record?"))
    {
      this.partners={
        'id':id,
        'deleted_by':1
      }
      this.http.post(this.configuration.url + '/DeletePartnerData',this.partners).subscribe((res:any)=>{
        this.getPartnersData();
        this.router.navigate(['/PartnerData']);
      })
    }
  }
  ngOnInit(): void {
    this.getPartnersData();
  }

}
