import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemorequestComponent } from './demorequest/demorequest.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { LoginComponent } from './login/login.component';
import { BloglistComponent } from './blogs/bloglist/bloglist.component';
import { BlogviewComponent } from './blogs/blogview/blogview.component';
import { InsertblogComponent } from './blogs/insertblog/insertblog.component';
import { UpdateblogComponent } from './blogs/updateblog/updateblog.component';
import { UsersComponent } from './users/users.component';
import { ProductlistComponent } from './products/productlist/productlist.component';
import { ProductsviewComponent } from './products/productsview/productsview.component';
import { InsertproductComponent } from './products/insertproduct/insertproduct.component';
import { UpdateproductComponent } from './products/updateproduct/updateproduct.component';
import { SubcriptionComponent } from './subcription/subcription.component';
import { PartnerdataComponent } from './partnerdata/partnerdata.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ContactusComponent } from './contactus/contactus.component';
import {LookUp} from "./common/lookUp";
import {DatePipe} from "@angular/common";
import { DemoviewpageComponent } from './demoviewpage/demoviewpage.component';
import { PartnerviewComponent } from './partnerview/partnerview.component';

import {TokenService} from './common/token.service';
// import {AuthService} from './common/auth.service';
// import {AuthGuard} from './auth.guard';

import {Localstorage} from './common/localstorage';
import {Securestorage} from './common/securestorage';
import {AuthGuard} from './auth.guard';
import {Editeroptions} from "./common/editeroptions";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    DemorequestComponent,
    LayoutComponent,
    LoginComponent,
    BloglistComponent,
    BlogviewComponent,
    InsertblogComponent,
    UpdateblogComponent,
    UsersComponent,
    ProductlistComponent,
    ProductsviewComponent,
    InsertproductComponent,
    UpdateproductComponent,
    SubcriptionComponent,
    PartnerdataComponent,
    ContactusComponent,
    DemoviewpageComponent,
    PartnerviewComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  providers: [
    LookUp,
    DatePipe,
    Localstorage,
    Securestorage,
    TokenService,
    AuthGuard,
    Editeroptions
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
