import { Injectable } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
@Injectable()
export class LookUp {
  constructor(private configuration:ConfigurationService,private http: HttpClient) {
  }
  getLookUp(value:string){
    switch (value) {
      case 'category':
        return this.http.get(this.configuration.url+'/getLookUp?lookuptype=category').pipe(map(res=>res));
    }
  }
}

