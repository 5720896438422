
import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import { HttpClient } from '@angular/common/http';
import {Common} from "../../common/common";
import {ActivatedRoute} from "@angular/router";
import {Router} from "@angular/router";
import {Sorting} from "../../common/sorting";
import {LookUp} from "../../common/lookUp";

declare var $: any;
@Component({
  selector: 'app-bloglist',
  templateUrl: './bloglist.component.html',
  styleUrls: ['./bloglist.component.css']
})
export class BloglistComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient,
              private router: Router,private lookUp:LookUp) { }

  blogdata: any = [];
  id: any;
  blogsData: any =[];
  imagePath: string = this.configuration.url+'/';
  Common = Common;
  Sorting = Sorting;
  order: boolean = true;
  property: any = null;
  p:number=1;
  items:number=10;
  totaldata:any=[];
  itemsvalue:number=10;
  itemvalue(){
    this.items=this.itemsvalue;
  }



  sorting(property, obj) {
    if (property == this.property) {
      this.property = property;
      this.order = !this.order;
      if (this.order) {
        $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
        $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
      } else {
        $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
        $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
      }
      Sorting.sort(property, obj, this.order);
    } else {
      this.property = property;
      this.order = false;
      $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
      $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
      Sorting.sort(property, obj, this.order);
    }
  }

  deleteBlog(id: any){
    if (confirm('Are you sure you want to delete this record?')) {
      this.blogdata = {
        'id': id,
        'deleted_by': '',
      };
      this.http.post(this.configuration.url + '/deleteblog', this.blogdata).subscribe((res: Object) => {
        this.getBlogs();
        this.router.navigate(['/blogs']);
      });
    }
  }

  getBlogs() {
    this.http.get(this.configuration.url + '/blogs').subscribe(data => {
      this.blogsData = data;
      this.totaldata=this.blogsData.length;
    });
  }
  navigateToViewBlog(id: string) {
    const encryptedId = Common.encryptId(id);
    this.router.navigate(['/viewblog', encryptedId]);
  }

  navigateToUpdateBlog(id: string) {
    const encryptedId = Common.encryptId(id);
    this.router.navigate(['/updateblog', encryptedId]);
  }

  ngOnInit(): void {
    this.getBlogs();

  }

}
