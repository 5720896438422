import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Localstorage} from './localstorage';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../services/configuration.service";

@Injectable()

export class TokenService {
  private data: any;
  constructor(private http: HttpClient,private router: Router,private Localsecurestorage: Localstorage ,private configuration:ConfigurationService){

    // this._messageService.currentDetail .subscribe(detail=>{
    //   console.log(detail['token']);  //detail is data you send in shared service
    // });
  }
  logout() {

    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(this.configuration.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + this.configuration.url).subscribe((res) => {
      if (res !== '' && res !== null && res !== undefined) {
        if (res['success'] == 'true') {
          this.http.post(this.configuration.serviceAuthorisationURL + '/updatetoken', {id: localStorage.getItem('userid'), url: this.configuration.url}).subscribe((data) => {});
        }
        this.Localsecurestorage.clearStorage();
        localStorage.clear();
        this.router.navigate(['']);
      }else{
        this.router.navigate(['']);
      }
    });
  }
  checkTokenAtLogin(){
    console.log(2)
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(this.configuration.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + this.configuration.url).subscribe((res) => {
      if (res !== '' && res !== null && res !== undefined) {
        res = res;
        if (res['success'] == 'true') {
          this.checkTokenAtAuthenticate();
          if(localStorage.getItem('landingroute')=='null'){
            this.router.navigate(['']);
          }else{
            this.router.navigateByUrl(localStorage.getItem('landingroute'));
          }
        }
      }
    });
  }
  checkTokenAtAuthGuard() {
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(this.configuration.serviceAuthorisationURL + '/authenticatetoken?token=' + localStorage.getItem('token') + '&id=' + localStorage.getItem('userid') + '&url=' + this.configuration.url).subscribe((data) => {
      this.data = data;
    });
    this.http.get(this.configuration.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + this.configuration.url ).subscribe(res => {
      if (res !== '' && res !== null && res !== undefined) {
        res = res;
        if (res['success'] == 'true') {
          this.checkTokenAtAuthenticate();
        }
        // else if (this.data['success'] == 'true') {
        //   this.checkTokenAtAuthenticate();
        // } else {
        //   alert('You have been signed out because you are currently signed in another device');
        //   this.router.navigate(['']);
        // }
      }
    });
  }

  checkTokenAtAuthenticate(){
    this.http.get(this.configuration.serviceAuthorisationURL + '/authenticatetoken?token=' + localStorage.getItem('token') + '&id=' + localStorage.getItem('userid') + '&url='+ this.configuration.url).subscribe((data) => {
      data = data;
      if(data['success']=='true') {
        this.http.post(this.configuration.serviceAuthorisationURL + '/updatetoken', {id: localStorage.getItem('userid'), token: data['token'] , url: this.configuration.url}).subscribe((res) => {
          localStorage.setItem('token', data['token']);
        });
      }

    });
  }

}
