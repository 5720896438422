import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DemorequestComponent} from "./demorequest/demorequest.component";
import {LayoutComponent} from "./shared/layout/layout.component";
import {BloglistComponent} from "./blogs/bloglist/bloglist.component";
import {BlogviewComponent} from "./blogs/blogview/blogview.component";
import {InsertblogComponent} from "./blogs/insertblog/insertblog.component";
import {UpdateblogComponent} from "./blogs/updateblog/updateblog.component";
import {LoginComponent} from "./login/login.component";
import {UsersComponent} from "./users/users.component";
import {ProductlistComponent} from "./products/productlist/productlist.component";
import {UpdateproductComponent} from "./products/updateproduct/updateproduct.component";
import {InsertproductComponent} from "./products/insertproduct/insertproduct.component";
import {ProductsviewComponent} from "./products/productsview/productsview.component";
import {SubcriptionComponent} from "./subcription/subcription.component";
import {PartnerdataComponent} from "./partnerdata/partnerdata.component";
import { ContactusComponent } from './contactus/contactus.component';
import { DemoviewpageComponent } from './demoviewpage/demoviewpage.component';
import { PartnerviewComponent } from './partnerview/partnerview.component';
import {AuthGuard} from './auth.guard';


const routes: Routes = [
  {path: '', component: LoginComponent},
  {
    path: '',
    component: LayoutComponent, canActivate: [AuthGuard],
    children : [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'demorequest', component: DemorequestComponent},
      {path: 'blogs', component: BloglistComponent},
      {path: 'viewblog/:id', component: BlogviewComponent},
      {path: 'insertblog', component: InsertblogComponent},
      {path: 'updateblog/:id', component: UpdateblogComponent},
      {path: 'users', component: UsersComponent},
      {path: 'products', component: ProductlistComponent},
      {path: 'viewproduct/:id', component: ProductsviewComponent},
      {path: 'insertproduct', component: InsertproductComponent},
      {path: 'updateproduct/:id', component: UpdateproductComponent},
      {path: 'subscribers', component: SubcriptionComponent},
      {path: 'partners' , component: PartnerdataComponent},
      {path: 'contact-us' , component: ContactusComponent},
      {path: 'viewdemorequest/:id', component: DemoviewpageComponent},
      {path: 'partnerview/:id', component: PartnerviewComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
