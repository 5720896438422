
import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {LookUp} from "../../common/lookUp";
import {Utility} from "../../common/utility";
import {Editeroptions} from "../../common/editeroptions";

declare var $:any;
@Component({
  selector: 'app-insertblog',
  templateUrl: './insertblog.component.html',
  styleUrls: ['./insertblog.component.css']
})


export class InsertblogComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router, private lookUp: LookUp) {
    this.blogForm = new FormGroup({
      blog_name: new FormControl('',[Validators.required]),
      category_id: new FormControl('',[Validators.required]),
      title:new FormControl('',[Validators.required]),
      publishdate:new FormControl('',[Validators.required]),
      description:new FormControl('',[Validators.required]),
      image:new FormControl('',[Validators.required])
    })
  }
  selectedFile:any;
  event1:any;
  submitted:boolean=false;
  imageError:any;
  recivedImgData:any;
  CategoriesData: any = [];
  blogForm: FormGroup;

  onFileSelected(event: any) {
    this.selectedFile=event.target.files[0];
  if (this.selectedFile.type!= "image/png" && this.selectedFile.type!= "image/jpeg")
  {
    this.imageError='Invalid image type';

  }
  else {
    this.imageError='';
  }
  }


  onFormSubmit()
  {
    this.submitted = true;
    if(this.blogForm.valid)
    {
      if (this.selectedFile.type== "image/png" || this.selectedFile.type== "image/jpeg")
      {
        const data=this.blogForm.value;
        const inputDate = new Date(data.publishdate);
        const formattedDate = inputDate.toISOString();
        let formData=new FormData();
        formData.append('file',this.selectedFile,this.selectedFile.name);
        formData.append('blog_name',data.blog_name);
        formData.append('category_id',data.category_id);
        formData.append('title',data.title);
        formData.append('publishdate',formattedDate);
        formData.append('description',data.description);

        this.http.post(this.configuration.url +'/insertblog', formData).subscribe(response => {

          this.router.navigate(['/blogs']);
        });
      }

    }


  }
  getLookUpdata() {
    this.lookUp.getLookUp('category').subscribe((data) => {
      this.CategoriesData = data;
    });
  }

  ngOnInit(): void {
    this.getLookUpdata();
    $('.navbar-collapse li.active').removeClass('active');
    $('#menusales').addClass('active');
    $('#description').froalaEditor({key: Utility.editorLicenseKey,height: 200,   placeholderText: "<br/>",toolbarButtons: Editeroptions.editerOptions()});


  }

}
