import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {Common} from "../common/common";
@Component({
  selector: 'app-demoviewpage',
  templateUrl: './demoviewpage.component.html',
  styleUrls: ['./demoviewpage.component.css']
})
export class DemoviewpageComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router, private route: ActivatedRoute) { }
  viewdemoData: any = [];
  id: any;
  DemoRequestId: any;
  Common = Common;
  deleteData(id:string)
  {
    if (confirm("Are you sure you want to delete this record?"))
    {
      this.DemoRequestId={
        'id':id,
        'deleted_by':1
      }
      this.http.post(this.configuration.url +'/deleteDemoRequest',this.DemoRequestId).subscribe({
        next:(res:any)=>
        {
          if (res['success']== "true")
            this.router.navigate(['/demorequest']);
        }
      });
    }
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    const encryptedId=Common.decryptId(this.id);
    this.http.get(this.configuration.url + '/viewdemo?id=' + encryptedId).subscribe((data)=> {
      this.viewdemoData = data;
    });
  }

}
