import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Utility} from "../../common/utility";
import {Editeroptions} from "../../common/editeroptions";
declare var $;

@Component({
  selector: 'app-insertproduct',
  templateUrl: './insertproduct.component.html',
  styleUrls: ['./insertproduct.component.css']
})
export class InsertproductComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient,private router: Router) {
  }
  productForm: FormGroup;
  submitted:boolean=false;

  saveProduct() {
    this.submitted=true;
    if (this.productForm.valid) {
      const inputData = this.productForm.value;
      this.http.post(this.configuration.url + '/insertproduct', inputData).subscribe({
        next: (res: any) => {
          this.router.navigate(['/products']);
        },
        error: (err: any) => {
          // Handle the error
        },
      });
    } else {
    }
  }

  ngOnInit(): void {
    this.productForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      USD_price: new FormControl('', Validators.required),
      INR_price: new FormControl('', Validators.required),
      AUD_price: new FormControl('', Validators.required),
      EUR_price: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      created_by: new FormControl('ritu'),
      modified_by: new FormControl('ritu'),

    });
    $('#description').froalaEditor({key: Utility.editorLicenseKey,height: 200,   placeholderText: "<br/>",toolbarButtons: Editeroptions.editerOptions()});

  }

}
