import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../services/configuration.service";
import {Router} from "@angular/router";
import {Utility} from "../common/utility";
import * as bcrypt from 'bcryptjs';

import {Localstorage} from '../common/localstorage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private Localsecurestorage: Localstorage, private router:Router) {

  }
  systemapi:any;
  id:any;
  status:any;
  userDetail:any;
  errorMessage:any;
  fieldTextType: boolean = true;
  pathopen = "M10 4.143c-3.618 0-6.745 2.094-8.253 5.138a1.62 1.62 0 0 0 0 1.438c1.508 3.044 4.635 5.138 8.253 5.138 3.618 0 6.745-2.094 8.253-5.138a1.62 1.62 0 0 0 0-1.438C16.745 6.237 13.618 4.143 10 4.143Zm0 9.762A3.902 3.902 0 0 1 6.104 10 3.902 3.902 0 0 1 10 6.095c2.15 0 3.896 1.75 3.896 3.905A3.902 3.902 0 0 1 10 13.905Zm0-6.248A2.337 2.337 0 0 0 7.662 10a2.337 2.337 0 1 0 4.676 0A2.337 2.337 0 0 0 10 7.657Z";
  pathclose = "M10.004 6.098A3.899 3.899 0 0 1 13.9 9.995c0 .507-.101.982-.28 1.426l2.275 2.276a9.226 9.226 0 0 0 2.355-2.983 1.617 1.617 0 0 0 0-1.438A9.212 9.212 0 0 0 9.995 4.15a9.077 9.077 0 0 0-3.102.546l1.683 1.683c.444-.179.92-.28 1.427-.28Zm-7.3-2.623a.7.7 0 0 0 0 .99l1.282 1.282.359.359a9.21 9.21 0 0 0-2.595 3.17 1.618 1.618 0 0 0 0 1.439 9.212 9.212 0 0 0 11.668 4.471l.327.328 1.788 1.782a.7.7 0 0 0 .99-.992L3.694 3.474a.7.7 0 0 0-.99 0ZM6.52 8.28l1.209 1.208a2.199 2.199 0 0 0-.063.507 2.335 2.335 0 0 0 2.339 2.338c.171 0 .343-.023.506-.062l1.208 1.208c-.522.257-1.099.413-1.714.413a3.899 3.899 0 0 1-3.898-3.897c0-.616.156-1.193.413-1.715Zm3.36-.608 2.455 2.455.016-.124a2.335 2.335 0 0 0-2.339-2.339l-.132.008Z";
  LoginForm: FormGroup;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  getSystemApi()
  {
      this.http.get(this.configuration.url + '/getsystemapis',).subscribe(systemapidata =>{
        this.systemapi = systemapidata;

        let core = this.systemapi.find(x => x.name == 'core');
        if (!Utility.isUndefined(core))
          localStorage.setItem('serviceCoreURL', core['url']);
      });
  }

    validatelogin(login)
  {
      if(!this.LoginForm.valid)
      {}
      else {
        this.http.get(this.configuration.url + '/getuser?email=' + login.username).subscribe(data=>{
          if (data === '' || data === null)
          {
            this.errorMessage = 'Invalid Username and Password !';
          }
          else
          {
            // this.getSystemApi();
            this.userDetail = data;
            this.status = bcrypt.compareSync(login.password,this.userDetail['password']);
            if(this.status === true)
            {
              this.http.post(this.configuration.serviceAuthorisationURL + '/login', {
                email: login.username,
                url: this.configuration.url
              }).subscribe(res=>{
                let token=res;
                localStorage.setItem('userid', this.userDetail['id']);
                localStorage.setItem('token', token['token']);
                this.http.post(this.configuration.serviceAuthorisationURL + '/updatetoken', {
                  id: localStorage.getItem('userid'),
                  token: token['token'],
                  url: this.configuration.url
                }).subscribe((res:any)=> {
                  this.Localsecurestorage.setStorage('useuserid', this.userDetail['id']);
                  this.Localsecurestorage.setStorage('useemail', this.userDetail['email']);
                  this.Localsecurestorage.setStorage('useusername', this.userDetail['username']);
                  this.Localsecurestorage.setStorage('firstname', this.userDetail['firstname']);
                  this.Localsecurestorage.setStorage('lastname', this.userDetail['lastname']);
                  this.Localsecurestorage.setStorage('uselandingroute', this.userDetail['landingroute']);
                  localStorage.setItem('email', this.userDetail['email']);
                  localStorage.setItem('lastname', this.userDetail['lastname']);
                  localStorage.setItem('firstname', this.userDetail['firstname']);
                  localStorage.setItem('username', this.userDetail['username']);
                  localStorage.setItem('profilephoto', this.userDetail['profilephoto']);
                  localStorage.setItem('landingroute', this.userDetail['landingroute']);
                  localStorage.setItem('password', login.password);
                  localStorage.setItem('activetab', 'lead');
                  if (res['success'] == 'true')
                  {
                   this.router.navigateByUrl(this.userDetail['landingroute']);
                  }
                  else{
                    this.router.navigate(['']);
                  }
                })
              })
            }
            else {
              this.errorMessage = 'Invalid Username and Password !';
            }
          }
        });
      }

  }
  ngOnInit(): void {
    this.toggleFieldTextType();
    this.LoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

}
