import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  p:number=1;
  items:number=10;
  totaldata:any=[];
  itemsvalue:number=10;
  ContactData:any;
  itemvalue(){
    this.items=this.itemsvalue;
  }
  constructor(private configuration:ConfigurationService , private http : HttpClient,private router:Router) { }
  ContactUs:any=[];
  getContactUs(){
    this.http.get(this.configuration.url + '/getContactUs').subscribe((data:any)=>{
      this.ContactUs=data;
      this.totaldata=data.length

    });
  }
  DeleteContact(id)
  {
    if (confirm("Are you sure.You want to delete recored"))
    {
      this.ContactData = {
        'id' : id,
        'deleted_by' : 1
      }
      this.http.post(this.configuration.url + '/DeleteContact',this.ContactData).subscribe((res:any)=>{
        this.getContactUs();
        this.router.navigate(['/contact-us']);
    });
    }
  }
  // previewpage()
  // {
  //   if (this.p >1)
  //   {
  //     this.p--
  //     this.items;
  //   }
  // }
  // nextpage()
  // {
  //   const allpages=Math.ceil(this.totaldata / this.items)
  //   if (this.p < allpages)
  //   {
  //     this.p++
  //     this.items;
  //   }
  // }
  ngOnInit(): void {
    this.getContactUs();
  }
}
