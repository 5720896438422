import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {Common} from "../../common/common";
import {Utility} from "../../common/utility";
import {Editeroptions} from "../../common/editeroptions";
declare var $;
@Component({
  selector: 'app-updateproduct',
  templateUrl: './updateproduct.component.html',
  styleUrls: ['./updateproduct.component.css']
})
export class UpdateproductComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

  product: any = [];
  id: any;
  myForm:FormGroup;
  updateProduct(){
    let inputData={
      id: this.product.id,
      name: this.product.name,
      INR_price: this.product.INR_price,
      USD_price: this.product.USD_price,
      EUR_price: this.product.EUR_price,
      AUD_price: this.product.AUD_price,
      description: this.product.description,
      modified_by: 'ritu'
    }

    if(inputData.id && inputData.name && inputData.description ) {
      this.http.post(this.configuration.url + '/updateproduct', inputData).subscribe({
        next: (res: any) => {
          // console.log(res, 'response');
          this.router.navigate(['/products']);
        },
        error: (err: any) => {
          // console.log(err, 'error');
        }
      });
    }else{
      // console.log("required")
    }
  }


  ngOnInit(): void {
this.myForm= new FormGroup({
  name:new FormControl('',null),
  INR_price:new FormControl('',null),
  AUD_price:new FormControl('',null),
  EUR_price:new FormControl('',null),
  USD_price:new FormControl('',null),
  description:new FormControl('',null),
});
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    const decriptedId=Common.decryptId(this.id);
    this.http.get(this.configuration.url + '/productdetails?id=' + decriptedId).subscribe(data => {
      this.product = data;
    });
    $('#description').froalaEditor({key: Utility.editorLicenseKey,height: 200,   placeholderText: "<br/>",toolbarButtons: Editeroptions.editerOptions()});

  }

}
