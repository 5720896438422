import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {TokenService} from '../../common/token.service';
import {Localstorage} from '../../common/localstorage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private Localsecurestorage: Localstorage, private Token: TokenService) { }
  shortName:any=[];
  id:any;
  profilephoto='';
  junctionbox='';
  lastname: string;
  firstname: string;
  rolename:any;
  LogoutUserSetFlag() {
    this.http.get(this.configuration.url + '/updateloggedinstatusdeactive?id=' + this.id).subscribe(res => {
    });
  }



  onLogout() {
    this.LogoutUserSetFlag();
    this.Token.logout();
  }
  ngOnInit(): void {
    this.id = this.Localsecurestorage.getStorage('useuserid');
    this.lastname = localStorage.getItem('lastname');
    this.firstname = localStorage.getItem('firstname');
    const name = this.firstname.charAt(0) + this.lastname.charAt(0);
    this.shortName = name.toUpperCase();
    // console.log('e',sessionStorage.getItem('eid'));
    // console.log('l',localStorage.getItem('employeeid'));
  }

}
