import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import { HttpClient } from '@angular/common/http';
import {Common} from "../../common/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css']
})
export class ProductlistComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient,private router:Router) { }

  products: any = [];
  p:number=1;
  items:number=10;
  totalproduct:any=[];
  itemsvalue:number=10;
  productDelete:any;
  itemvalue(){
    this.items=this.itemsvalue;
  }

  // previewpage()
  // {
  //   if (this.p >1)
  //   {
  //     this.p--
  //     this.items;
  //   }
  // }
  // nextpage()
  // {
  //   const allpages=Math.ceil(this.totalproduct / this.items)
  //   if (this.p < allpages)
  //   {
  //     this.p++
  //     this.items;
  //   }
  // }

  navigateToproductEdit(id)
  {
    const encryptedId=Common.encryptId(id);
    this.router.navigate(['/updateproduct',encryptedId]);
  }
  navigateToViewPage(id)
  {
    const encryptedId=Common.encryptId(id);
    this.router.navigate(['/viewproduct',encryptedId]);
  }
  getProducts() {
    this.http.get(this.configuration.url + '/products').subscribe((data:any)=>{
      this.products = data;
      this.totalproduct=data.length
    })
  }
  deleteProduct(id)
  {
    if (confirm('Are you sure,you want to delete this record?'))
    {
      this.productDelete={
        'id':id,
        'deleted_by': ''
      }
      this.http.post(this.configuration.url + '/deleteproduct',this.productDelete).subscribe((res:any)=>{
        this.getProducts();
        this.router.navigate(['/products']);
      });
    }
  }
  ngOnInit(): void {
    this.getProducts();
  }
}
