import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Common} from '../common/common';
import {Router} from '@angular/router';
import {ConfigurationService} from '../services/configuration.service';

declare var $;
@Component({
  selector: 'app-demorequest',
  templateUrl: './demorequest.component.html',
  styleUrls: ['./demorequest.component.css']
})
export class DemorequestComponent implements OnInit {
  p: any = 1;
  items: any = 10;
  totalproduct: any = [];
  itemsvalue: any = 10;
  DemoRequestId: any;
  DemoRequestDataId: any;
  selectValue: any;
  status: any;
  DemoRequestData: any = [];
  itemvalue(){
    this.items = this.itemsvalue;
  }
  constructor(private configuration: ConfigurationService , private http: HttpClient, private router: Router) { }

  Common = Common;
  getDemoRequestData() {
    this.http.get(this.configuration.url + '/getDemoRequestData').subscribe((data: any) => {
      this.DemoRequestData = data;
      this.totalproduct = data.length;
    });
  }

  getDataId(id)
  {
    this.DemoRequestDataId=id;
    this.DemoRequestData.map((item) => {
      if(item.id == id){
        if (item.active == 1)
        {
          this.selectValue = '1'
        }
        else {
          this.selectValue= '0'
        }
      }
    })
  }
  ChangeStatus(id)
  {
      const DataId=id;
      let status =this.status;
      if (status != null)
      {
        let StatusData=
          {
            id:DataId,
            status:status
          }
        this.http.post(this.configuration.url + '/changeDemoRequestStatus',StatusData).subscribe((data)=>{
          this.selectValue = null
          this.status=null
          $('.dialog').modal('hide')
          this.getDemoRequestData();
        });
      }
  }
  ViewDemo(id:any)
  {
    const encrytedId=Common.encryptId(id);
    this.router.navigate(['/viewdemorequest',encrytedId]);
  }
  deleteData(id:string)
  {
    if (confirm("Are you sure you want to delete this record?"))
    {
      this.DemoRequestId={
        'id':id,
        'deleted_by':1
      }
      this.http.post(this.configuration.url +'/deleteDemoRequest',this.DemoRequestId).subscribe({
        next:(res:any)=>
      {
        if (res['success']== "true")
        this.router.navigate(['demorequest']);
        this.getDemoRequestData()
      }
      });
    }
  }

  ngOnInit(): void {
    this.getDemoRequestData();
  }

}
