import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
declare var $:any;
@Component({
  selector: 'app-subcription',
  templateUrl: './subcription.component.html',
  styleUrls: ['./subcription.component.css']
})
export class SubcriptionComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient,private router:Router) {
    this.changeStatusForm=new FormGroup({
    status:new FormControl('',[Validators.required,Validators.pattern(/^[0-9]+$/)])
    });
  }
  subscribeData:any=[];
  p:number=1;
  items:number=10;
  totalsubscribers:any=[];
  itemsvalue:number=10;
  subscriberData:any;
  subscriberId:any;
  selectValue:string = null;
  changeStatusForm:FormGroup;
  getSubcription() {
    this.http.get(this.configuration.url + '/getsubscribers').subscribe((data :any )=> {
      this.subscribeData = data;
      this.totalsubscribers=data.length
    });
  }
  getDataId(id)
  {
    this.subscriberId=id;
    this.subscribeData.map((item) => {
      if(item.id == id){
        if (item.active == 1)
        {
          this.selectValue = '1'
        }
        else {
          this.selectValue= '0'
        }
      }
    })
  }
  changeStatus(id)
  {
    if (this.changeStatusForm.valid)
    {
      const DataId=id;
      let status =this.changeStatusForm.value;
      let StatusData=
        {
          id:DataId,
          status:status
        }
      this.http.post(this.configuration.url + '/changeStatus',StatusData).subscribe((data)=>{
        this.selectValue = null
        $('.dialog').modal('hide')
        this.getSubcription();
      });
    }
  }
  itemvalue(){
    this.items=this.itemsvalue;
  }
  deleteData(id)
  {
    if (confirm('Are you sure you want to delete this record?'))
    {
      this.subscriberData={
        'id':id,
        'deleted_by':1
      }
      this.http.post(this.configuration.url + '/deleteSubscriber',this.subscriberData).subscribe((res:any)=>{
        this.getSubcription();
        this.router.navigate(
          ['/subscriber']
        );
      });
    }
  }
  // previewpage()
  // {
  //   if (this.p >1)
  //   {
  //     this.p--
  //     this.items;
  //   }
  // }
  // nextpage()
  // {
  //   const allpages=Math.ceil(this.totalsubscribers / this.items)
  //   if (this.p < allpages)
  //   {
  //     this.p++
  //     this.items;
  //   }
  // }

  ngOnInit(): void {
    this.getSubcription();

  }

}
