import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private configuration:ConfigurationService,private http:HttpClient) { }
  DashboardData:any=[];
  getDashboradData()
  {
    this.http.get(this.configuration.url+'/getDashboardData' ).subscribe(data=>{
      this.DashboardData=data;
    })
  }
  ngOnInit(): void {
    this.getDashboradData();
  }

}
