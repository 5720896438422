import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Common} from "../../common/common";
import {LookUp} from "../../common/lookUp";
import {DatePipe} from "@angular/common";
import {Utility} from "../../common/utility";
import {Editeroptions} from "../../common/editeroptions";
declare var $:any;
@Component({
  selector: 'app-updateblog',
  templateUrl: './updateblog.component.html',
  styleUrls: ['./updateblog.component.css']
})
export class UpdateblogComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router, private route: ActivatedRoute
    ,private lookUp:LookUp,private datePipe:DatePipe) { }

  blogdata: any = {};
  CategoriesData: any = [];
  id: any;
  imageError: any;
  Common = Common;
  selectedFile:any;
  imagePath: string = this.configuration.url+'/';

  onFileSelected(event: any) {
    this.selectedFile=event.target.files[0];

    if (this.selectedFile.type!= "image/png" && this.selectedFile.type!= "image/jpeg")
    {
      this.imageError='Invalid image type';

    }
    else {
      this.imageError='';
    }
  }

  updateBlog(){
    if (this.blogdata.image || this.selectedFile.type== "image/png" || this.selectedFile.type== "image/jpeg") {
      const inputDate = new Date(this.blogdata.publishdate);
      const formattedDate = inputDate.toISOString();
      let formData = new FormData();
      if (this.selectedFile) {
        formData.append('file', this.selectedFile, this.selectedFile.name);
      } else {
        formData.append('image', this.blogdata.image)
      }
      formData.append('id', this.blogdata.id);
      formData.append('name', this.blogdata.name);
      formData.append('category_id', this.blogdata.category_id);
      formData.append('title', this.blogdata.title);
      formData.append('publishdate', formattedDate);
      formData.append('description', this.blogdata.description);
      if (this.blogdata.id && this.blogdata.name && this.blogdata.description) {
        this.http.post(this.configuration.url + '/updateblog', formData).subscribe({
          next: (res: any) => {
            this.router.navigate(['/blogs']);
          },
          error: (err: any) => {
            // console.log(err, 'error');
          }
        });
      } else {
        // console.log("required")
      }
    }
  }
  getLookUpdata() {
    this.lookUp.getLookUp('category').subscribe((data) => {
      this.CategoriesData = data;
    });
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id =  Common.decryptId((params.get('id')));
    });
    this.http.get(this.configuration.url + '/blogdetails?id=' + this.id).subscribe(data => {
      this.blogdata = data;
      this.blogdata['publishdate'] = this.datePipe.transform(this.blogdata.publishdate, 'yyyy-MM-dd');
      // }
    });
    $('#description').froalaEditor({key: Utility.editorLicenseKey,height: 200,   placeholderText: "<br/>",toolbarButtons: Editeroptions.editerOptions()});

    this.getLookUpdata();
  }

}
