import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

   // url:string = 'http://localhost:8009';
   // serviceAuthorisationURL:string = "http://localhost:8007";

  url:string = 'https://greenmonkeyservicecore.octanics.com';
  serviceAuthorisationURL:string = "https://greenmonkeyserviceauthentication.octanics.com";


}

