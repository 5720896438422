import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../../services/configuration.service";
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {Common} from "../../common/common";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-blogview',
  templateUrl: './blogview.component.html',
  styleUrls: ['./blogview.component.css']
})
export class BlogviewComponent implements OnInit {

  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) { }
  imagePath: string = this.configuration.url+'/';
  blogdata: any = [];
  id: any;
  Common = Common;
  deleteBlog(id: any){
    if (confirm('Are you sure you want to delete this record?')) {
      this.blogdata = {
        'id': id,
        'deleted_by': 'ritu',
      };
      this.http.post(this.configuration.url + '/deleteblog', this.blogdata).subscribe((res: Object) => {
        this.router.navigate(['/blogs']);
      });
    }
  }
  navigateToUpdateBlog(id: string) {
    const encryptedId = Common.encryptId(id);
    this.router.navigate(['/updateblog', encryptedId]);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id =  Common.decryptId((params.get('id')));
    });

    this.http.get(this.configuration.url + '/blogdetails?id=' + this.id).subscribe(data => {
      this.blogdata = data;
      this.blogdata['description']=this.sanitizer.bypassSecurityTrustHtml(this.blogdata['description'])
    });
  }

}
