import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from "../services/configuration.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Common} from "../common/common";
import {debugOutputAstAsTypeScript} from "@angular/compiler";
declare var $ ;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {


  constructor(private configuration: ConfigurationService, private http: HttpClient, private router: Router) { }
  p:number=1;
  items:number=10;
  totalproduct:any=[];
  users:any=[];
  itemsvalue:number=10;
  UserDataId:any;
  selectValue:number;
  status:any;
  itemvalue(){
    this.items=this.itemsvalue;
  }
  userData:any=[];

  getDataId(id)
  {
    this.UserDataId=id;
    this.userData.map((item) => {
      if(item.id == id){
        if (item.active == 1)
        {
          this.selectValue = 1
        }
        else {
          this.selectValue=0
        }
      }
    })
  }
  ChangeStatus(id)
  {
    const DataId=id;
    let status =this.status;
    if (status !=null)
    {
      let StatusData=
        {
          id:DataId,
          status:status
        }
      this.http.post(this.configuration.url + '/changeuserdatastatus', StatusData).subscribe((data)=>{
        this.selectValue = null
        this.status=null
        $('.dialog').modal('hide')
        this.getUserData();
      });
    }
  }

  getUserData(){
    this.http.get(this.configuration.url + '/getuserdata' ).subscribe((data:any)=>{
      this.userData=data;
      console.log("data",data)
      this.totalproduct=data.length
    })
  }
  deleteData(id:any)
  {
    if (confirm("Are you sure you want to delete this record?"))
    {
      this.users={
        'id':id,
        'deleted_by':1
      }
      this.http.post(this.configuration.url + '/Deleteuserdata',this.users).subscribe((res:any)=>{
        this.getUserData();
        this.router.navigate(['/users']);
      })
    }
  }
  ngOnInit(): void {
    this.getUserData();
  }

}
