import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {TokenService} from './common/token.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private Token: TokenService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('userid')) {
      this.Token.checkTokenAtAuthGuard();
      // logged in so return true
      return true;
    }
    else
    {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['']);
      // {queryParams: {returnUrl: state.url}});
      return false;
    }


  }
}




